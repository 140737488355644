const { REACT_APP_CC_API_BASE_URL } = process.env;
export const CC_API_BASE_URL = REACT_APP_CC_API_BASE_URL || 'http://localhost:8080/v1/api';

/** -- MISC -- **/
export const WORKER_URL = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

/**
 * Replaces placeholders in the string with values from mapObj
 * @example
 *   str = 'Hello {name}, today is {today}'
 *   name = 'Alice';
 *   today: 'Monday',
 *   replacePlaceholders(str, { name, today })
 *
 * Returns 'Hello Alice, today is Monday'
 *
 * Based on https://stackoverflow.com/a/15604206
 */
export const replacePlaceholders = (str: any, mapObj: any) => {
  if (!mapObj) return str;

  return str.replace(
    new RegExp(
      Object.keys(mapObj)
        .map(key => `{${key}}`)
        .join('|'),
      'gi',
    ),
    (matched: any) => mapObj[matched.substring(1, matched.length - 1)],
  );
};

export namespace UserUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/users`;

  export const LOGIN = `${BASE_URL}/login`;
  export const LIST = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const LOCK = `${BASE_URL}/{id}/lock`;
  export const UNLOCK = `${BASE_URL}/{id}/unlock`;
  export const CLOSE = `${BASE_URL}/{id}/close`;
  export const APPROVE = `${BASE_URL}/{id}/approve`;
  export const INVEST_POST_CLOSING_OFFER = `${BASE_URL}/{id}/offers/post-close`;
  export const ENABLE_MFA = `${BASE_URL}/settings/mfa-preferences/enable`;
  export const DISABLE_MFA = `${BASE_URL}/settings/mfa-preferences/disable`;
}

export namespace LegacyUserUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/users/old`;

  export const LIST = `${BASE_URL}`;
}

export namespace InvestigationUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/investigations/{accountId}`;

  export const LIST = `${BASE_URL}`;
  export const APPEAL = `${BASE_URL}/investigation/appeal`;
  export const REJECT = `${BASE_URL}/investigation/reject`;
  export const SUBMIT_DOCUMENT = `${BASE_URL}/investigation/submit/{miscDocumentId}`;
  export const UPLOAD_DOCUMENT = `${BASE_URL}/investigation/{investigationId}/documents`;
  export const DOWNLOAD_DOCUMENT = `${BASE_URL}/investigation/{investigationId}/documents/{documentId}`;
  export const DELETE_DOCUMENT = `${BASE_URL}/investigation/{miscDocumentId}`;

  export const CONTINUE_APPLICATION = `${BASE_URL}/application/continue`;
  export const SUBMIT_APPLICATION_DOCUMENT = `${BASE_URL}/application/submit/{miscDocumentId}`;
  export const UPLOAD_APPLICATION_DOCUMENT = `${BASE_URL}/application/{accountHolderId}/documents`;
  export const DOWNLOAD_APPLICATION_DOCUMENT = `${BASE_URL}/application/{accountHolderId}/documents/{documentId}`;
  export const DELETE_APPLICATION_DOCUMENT = `${BASE_URL}/application/{miscDocumentId}`;
}

export namespace AccountUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/accounts`;

  export const CREATE = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const PATCH = `${BASE_URL}/{id}`;
  export const PATCH_JOINT_INTEREST = `${BASE_URL}/{id}/joint-interest`;
  export const SWITCH = `${BASE_URL}/{id}/switch`;
  export const SUBMIT = `${BASE_URL}/{id}/submit`;
  export const DOWNLOAD_AGREEMENT = `${BASE_URL}/{id}/agreement`;
  export const DOWNLOAD_SIGNATURE = `${BASE_URL}/{id}/signatures`;
  export const SYNC_STATUS_LIST = `${BASE_URL}/sync-status`;
}

export namespace AccountHolderUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/accounts/{id}/account-holders/{accountHolderId}`;
  const ACCOUNT_BASE_URL = `${CC_API_BASE_URL}/accounts/{id}`;

  export const PATCH = `${BASE_URL}`;
  export const PHYSICAL_ADDRESS = `${BASE_URL}/physical-address`;
  export const MAILING_ADDRESS = `${BASE_URL}/mailing-address`;
  export const TRUSTED_CONTACT = `${ACCOUNT_BASE_URL}/trusted-contact`;
  export const SUITABILITY = `${ACCOUNT_BASE_URL}/suitability-information`;
  export const FINANCIAL_ASSET = `${ACCOUNT_BASE_URL}/financial-asset`;
  export const FINANCIAL_EMPLOYMENT = `${BASE_URL}/financial-employment`;
  export const DISCLOSURE = `${BASE_URL}/disclosure`;

  export const LIST_UPDATE_REQUESTS = `${BASE_URL}/update-requests`;
  export const APPROVE_UPDATE_REQUEST = `${BASE_URL}/update-requests/{requestId}/approve`;
  export const REJECT_UPDDATE_REQUEST = `${BASE_URL}/update-requests/{requestId}/reject`;
}

export namespace AccountBalanceUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/balances`;

  export const LIST = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{accountId}`;
}

export namespace OfferUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/offers`;

  export const LIST = `${BASE_URL}/details`;
  export const RETRIEVE = `${BASE_URL}/{id}/details`;
  export const LIST_PUBLIC = `${BASE_URL}`;
  export const RETRIEVE_PUBLIC = `${BASE_URL}/{id}`;
  export const CREATE = `${BASE_URL}`;
  export const PATCH = `${BASE_URL}/{id}`;
  export const COMPLETE = `${BASE_URL}/{id}/orders/complete`;
  export const NEW_CARD_PAYMENT_ACCOUNT_LINK = `${BASE_URL}/{id}/card-payments/new-account-link`;
}

export namespace OfferOptionUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/offer-options/{type}`;

  export const CREATE = `${BASE_URL}`;
  export const LIST = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const PATCH = `${BASE_URL}/{id}`;
  export const DELETE = `${BASE_URL}/{id}`;
}

export namespace OfferDocumentUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/offer-documents`;

  export const CREATE = `${BASE_URL}`;
  export const LIST = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const PATCH = `${BASE_URL}/{id}`;
  export const DELETE = `${BASE_URL}/{id}`;
  export const CREATE_UPLOAD_LINK = `${CC_API_BASE_URL}/s3/presigned-url`; // TODO: move presigned url in offer documents model
}

export namespace OfferWatchUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/offer-watches`;

  export const CREATE = `${BASE_URL}`;
  export const LIST = `${BASE_URL}`;
  export const LIST_ALL = `${BASE_URL}/all`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const DELETE = `${BASE_URL}/{id}`;
}

export namespace SnapQuoteUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/quote-media`;

  export const LIST = `${BASE_URL}/snap-quotes/{symbols}`;
}

export namespace PrivateQuoteUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/private-quotes`;

  export const CREATE = `${BASE_URL}`;
  export const LIST = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const PATCH = `${BASE_URL}/{id}`;
  export const DELETE = `${BASE_URL}/{id}`;
  export const SNAP = `${BASE_URL}/snap`;
}

export namespace ConfigUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/configs`;

  export const CREATE = `${BASE_URL}`;
  export const LIST = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const PATCH = `${BASE_URL}/{id}`;
  export const DELETE = `${BASE_URL}/{id}`;
}

export namespace OfferOrderUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/offer-orders`;

  export const CREATE = `${BASE_URL}`;
  export const LIST = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const UPDATE = `${BASE_URL}/{id}`;
  export const UPDATE_LIST_STATUS = `${BASE_URL}/status`;
  export const DELETE = `${BASE_URL}/{id}`;
  export const AGGREGATE = `${BASE_URL}/aggregate`;
  export const CREATE_CARD_PAYMENT_INTENT = `${BASE_URL}/card-payment-intents`;
  export const UPDATE_CARD_PAYMENT_INTENT = `${BASE_URL}/card-payment-intents/{id}`;
  export const CREATE_CARD_PAYMENT_REFUND = `${BASE_URL}/{id}/refunds`;
  export const LIST_CARD_PAYMENTS = `${BASE_URL}/card-payments`;
  export const RETRIEVE_CARD_PAYMENT = `${BASE_URL}/card-payments/{id}`;
}

export namespace OrderUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/orders`;

  export const CREATE = `${BASE_URL}/accounts/{accountId}`;
  export const LIST = `${BASE_URL}/accounts/{accountId}`;
  export const RETRIEVE = `${BASE_URL}/{id}/accounts/{accountId}`;
  export const CANCEL = `${BASE_URL}/{id}/accounts/{accountId}/cancel`;
  export const POSITION_LIST = `${BASE_URL}/accounts/{accountId}/positions`;
}

export namespace AchRelationshipUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/cashiering-ach`;

  export const CREATE = `${BASE_URL}/{accountId}/create`;
  export const SEARCH = `${BASE_URL}/relationships/search`;
  export const LIST = `${BASE_URL}/relationships/{accountId}`;
  export const RETRIEVE = `${BASE_URL}/relationshipDetails/{id}`;
  export const APPROVE = `${BASE_URL}/{accountId}/approve`;
  export const REJECT = `${BASE_URL}/{accountId}/reject`;
  export const CANCEL = `${BASE_URL}/{accountId}/cancel`;
  export const UPDATE = `${BASE_URL}/{accountId}/update`;
}

export namespace TransferUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/cashiering-transfer`;

  export const SEARCH = `${BASE_URL}/transfers/search`;
  export const LIST = `${BASE_URL}/{accountId}/transfers`;
  export const RETRIEVE = `${BASE_URL}/{id}/details`;
  export const FIRM_APPROVE = `${BASE_URL}/{accountId}/transfer/{id}/approveCreateFirm`;
  export const REP_APPROVE = `${BASE_URL}/{accountId}/transfer/{id}/approveCreateRep`;
  export const CANCEL = `${BASE_URL}/{accountId}/transfer/{id}/cancel`;
  export const REJECT = `${BASE_URL}/{accountId}/transfer/{id}/reject`;
  export const UPDATE = `${BASE_URL}/{accountId}/transfer/{id}/update`;
}
